import { useSelector } from 'react-redux';
import { formatNetwork, nativeTokenByNetwork } from '../../../utils/networkTools';
import capitalize from '../../../utils/capitalize';
import { baseCard } from '../../../config/tailwind.classnames';

import StatusTile from '../../../utils/StatusTile';
import TxActions from './actions/TxActions';
import { CopyToClipboard } from '../../../assets/icons/icons';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { formatIntegerAmount } from '../../../utils/networkTools';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { urls } from '../../../config';
import { httpGet } from '../../../store/axios';
import { toast } from 'react-hot-toast';
import { Loading } from '../../../assets/icons/loading';
import { NetworkLogo } from '../../../assets/networks';

/**
 * @description - Construct a transaction card by inserting known transaction data.
 * @returns {*} - the transaction card JSX Element
 */
export function TransactionCard({ transaction, onClose }) {
  return (
    <div className="h-max max-w-[1400px] !min-w-fit sm:px-6">
      <div className={`${baseCard} !min-w-fit sm:text-xs !p-10 !rounded-lg !bg-black shadow-3xl`}>
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            <span className="sm:text-lg md:text-xl lg:text-2xl font-bold text-gray-100">
              Withdrawal Details
            </span>
            <div className="w-6">
              <NetworkLogo
                network={nativeTokenByNetwork(transaction?.network)}
                token={transaction?.asset}
                size="s"
              />
            </div>
          </div>
          <XMarkIcon
            onClick={onClose}
            className="w-8 hover:cursor-pointer hover:stroke-2 hover:text-gray-400"
          />
        </div>
        <TransactionCardData tx={transaction} onClose={onClose} />
      </div>
    </div>
  );
}

/**
 * @description - Construct a transaction card by querying the API for the transaction specified in the route parameter `id`.
 * @returns {*} - the transaction card JSX Element
 */
export function TransactionCardFromParams() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState(null);
  useEffect(() => {
    httpGet(`${urls.transactionUrl}/status/${id}`)
      .then(res => setTransaction(res))
      .catch(err => toast.error(`${err}`));
  }, [id]);
  return TransactionCard({ transaction, onClose: () => navigate('/dashboard/withdrawals') });
}

function TransactionCardData({ tx, onClose }) {
  const currentUser = useSelector(state => state.session.user);

  if (!tx) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex pt-2">
        <StatusTile status={tx.approval.status} />
        <span className="pr-2" />
        {tx.txProcessingStatus !== 'pending' && <StatusTile status={tx.txProcessingStatus} />}
      </div>
      <div className="pt-8 text-sm">
        <table className="w-full">
          <tbody>
            <tr className="border-t" />
            <tr className="h-5" />

            <tr>
              <td className="w-48">Transaction ID:</td>
              <td className="sm:text-xs md:text-xs lg:text-sm flex">
                <span>{tx.txId}</span>
                <span className="text-gray-400 ml-2">
                  <CopyToClipboard label="Transaction ID" text={tx.txId} />
                </span>
              </td>
            </tr>
            {tx.txHash && (
              <tr>
                <td className="w-48">Transaction Hash:</td>
                <td className="sm:text-xs md:text-xs lg:text-sm flex">
                  <span>
                    {tx.explorerUrl ? (
                      <a href={tx.explorerUrl} target="_blank" rel="noreferrer">
                        {tx.txHash}
                      </a>
                    ) : (
                      tx.txHash
                    )}
                  </span>
                  <span className="text-gray-400 ml-2">
                    <CopyToClipboard label="Transaction Hash" text={tx.txHash} />
                  </span>
                </td>
              </tr>
            )}
            <tr className="h-5" />
            <tr>
              <td className="w-48 min-w-[144px]">Date Created:</td>
              <td>{new Date(tx.dateCreated).toUTCString()}</td>
            </tr>

            {tx.dateProcessed && (
              <tr>
                <td className="flex w-48 min-w-[144px] items-center">
                  <span>Date Processed:</span>
                  <span className="hover:text-green-500 ml-1 cursor-pointer">
                    <InformationCircleIcon className="w-3" />
                  </span>
                </td>
                <td>{new Date(tx.dateProcessed).toUTCString()}</td>
              </tr>
            )}
            {tx.dateVerified && (
              <tr>
                <td className="w-48 min-w-[144px]">Date Verified:</td>
                <td>{new Date(tx.dateVerified).toUTCString()}</td>
              </tr>
            )}
            <tr className="h-5 border-b" />
            <tr className="h-5" />
            <tr>
              <td className="w-48 min-w-[144px]">Requester:</td>
              <td>{tx.requestingManager}</td>
            </tr>
            <tr>
              <td className="w-48 min-w-[144px]">Wallet Owner:</td>
              <td>{tx.owner}</td>
            </tr>
            <tr>
              <td className="w-48 min-w-[144px]">Wallet ID:</td>
              <td className="sm:text-xs md:text-xs lg:text-sm flex">
                <span>
                  <a
                    href={`/dashboard/wallets/${tx.walletId}`}
                    className="text-blue-400 underline hover:text-blue-700"
                  >
                    {tx.walletId}
                  </a>
                </span>
                <span className="text-gray-400 ml-2">
                  <CopyToClipboard label="Wallet ID" text={tx.walletId} />
                </span>
              </td>
            </tr>
            <tr className="h-5 border-b" />
            <tr className="h-5" />
            <tr>
              <td className="w-48 min-w-[144px]">Network:</td>
              <td>{capitalize(tx.network)}</td>
            </tr>

            <tr className="h-2" />
            <tr>
              <td className="w-48 min-w-[144px]">From:</td>
              <td className="sm:text-xs md:text-xs lg:text-sm flex">
                <span>{tx.originAddress}</span>
                <span className="text-gray-400 ml-2">
                  <CopyToClipboard label="Origin address" text={tx.originAddress} />
                </span>
              </td>
            </tr>
            <tr>
              <td className="w-48 min-w-[144px]">To:</td>
              <td className="sm:text-xs md:text-xs lg:text-sm flex">
                <span>{tx.destinationAddress}</span>
                <span className="text-gray-400 ml-2">
                  <CopyToClipboard label="Destination address" text={tx.destinationAddress} />
                </span>
              </td>
            </tr>
            <tr className="h-5 border-b" />
            <tr className="h-5" />
            <tr>
              <td className="w-48 min-w-[144px]">Value:</td>
              <td>
                {tx.amount} {tx.asset.toUpperCase()} ({capitalize(tx.network)})
              </td>
            </tr>
            <tr>
              <td className="w-48 min-w-[144px]">Fee:</td>
              {tx.fee && (
                <td>
                  {formatIntegerAmount(tx.fee, tx.network !== 'ethereum' ? tx.asset : 'eth')}{' '}
                  {formatNetwork(tx.network)}
                </td>
              )}
            </tr>
            <tr className="h-5 border-b" />
            <tr className="h-5" />
            {tx.approval.status !== 'pending' && (
              <tr className={tx.approval.status === 'approved' ? 'text-green-400' : 'text-red-400'}>
                <td>{capitalize(tx.approval.status)}:</td>
                <td>{tx.approval.approvedBy}</td>
              </tr>
            )}
            <tr></tr>
          </tbody>
        </table>
      </div>
      <ApproveOrRejectActions userEmail={currentUser.email} tx={tx} />
    </>
  );
}

function ApproveOrRejectActions({ userEmail, tx }) {
  const [managers, setManagers] = useState(null);
  const [err, setErr] = useState(null);

  useEffect(() => {
    httpGet(`${urls.apiUrl}/wallets/id/${tx.walletId}/managers`)
      .then(res => setManagers(res.managers))
      .catch(e => setErr(e));
  }, [tx.walletId]);

  if (err) {
    toast.error('Failed to retrieve wallet metadata');
    return null;
  }

  if (!managers) {
    return <Loading />;
  }

  if (userEmail === tx?.requestingManager && tx?.approval.status === 'pending') {
    return (
      <div className="text-center text-gray-400">
        You cannot approve / reject your own transaction
      </div>
    );
  }

  if (!managers.includes(userEmail) && tx.status !== 'pending') {
    return (
      <div className="text-center text-gray-400">
        Only wallet managers can approve / reject transactions
      </div>
    );
  }

  return <TxActions tx={tx} />;
}
