import { baseCard } from "../../../config/tailwind.classnames";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { formatIntegerAmount, nativeTokenByNetwork } from '../../../utils/networkTools';
import { useDispatch } from "react-redux";
import { hideModal } from "../../../store/modal";
import { CopyToClipboard } from '../../../assets/icons/icons';
import { NetworkLogo } from '../../../assets/networks';

const DepositTable = ({ deposit }) => (
  <div className="mt-8 text-sm">
    <table className="w-full">
      <tbody>
        <tr className="border-t" />
        <tr className="h-5" />

        <tr>
          <td className="text-gray-400 w-48 min-w-[144px]">Wallet ID</td>
          <td className="sm:text-xs md:text-xs lg:text-sm flex">
            <span>
              <a href={`/dashboard/wallets/${deposit.walletId}`} class="text-blue-400 underline hover:text-blue-700">
                {deposit.walletId}
              </a>
            </span>
            <span className="text-gray-400 ml-2">
              <CopyToClipboard label="Wallet ID" text={deposit.walletId} />
            </span></td>
        </tr>
        <tr>
          <td className="text-gray-400 w-48 min-w-[144px]">Owner</td>
          <td className="">{deposit.owner}</td>
        </tr>
        <tr className="h-5" />

        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Transaction Hash</td>
          <td className="sm:text-xs md:text-xs lg:text-sm flex">
            <span>
              {deposit.explorerUrl ? <a href={deposit.explorerUrl} target="_blank">{deposit.txHash}</a> : deposit.txHash}
            </span>
            <span className="text-gray-400 ml-2">
              <CopyToClipboard label="Transaction Hash" text={deposit.txHash} />
            </span></td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Amount</td>
          <td className="">{deposit.amount}</td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Amount (base units)</td>
          <td className="">
            {formatIntegerAmount(deposit.amount, deposit.asset)}
          </td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Asset (base units)</td>
          <td className="">{deposit.asset?.toUpperCase()}</td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Date Seen</td>
          <td className="">
            {new Date(deposit.seenAt)?.toLocaleString()} (
            {new Date(deposit.seenAt)?.toISOString()})
          </td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Block Number</td>
          <td className="">{deposit.blockNumber}</td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Origin</td>
          <td className="">
            {deposit.originAddress || deposit.originAddresses.join(", ")}
          </td>
        </tr>
        <tr className="border-t border-dotted border-gray-500">
          <td className="py-1 w-48 min-w-[144px]">Destination</td>
          <td className="">
            {deposit.destinationAddress ||
              deposit.destinationAddresses.join(", ")}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

function DepositCard({ deposit }) {
  const dispatch = useDispatch();

  return (
    <div className="h-max max-w-[1400px] !min-w-[500px] sm:px-6">
      <div
        className={`${baseCard} !min-w-[500px] sm:text-xs !p-10 !rounded-lg !bg-black shadow-3xl`}
      >
        <div className="flex justify-between">
          <div className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-100">
            <div className="flex items-center space-x-2">
              <span>Deposit Details</span>
              <span className="w-6">
                <NetworkLogo
                  network={nativeTokenByNetwork(deposit.network)}
                  token={deposit.asset}
                  size="s"
                />
              </span>
            </div>
          </div>
          <XMarkIcon onClick={() => dispatch(hideModal())} className="w-8 hover:cursor-pointer" />
        </div>
        <DepositTable deposit={deposit} />
      </div>
    </div>
  );
}

export default DepositCard;
