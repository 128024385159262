/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",

  // development
  // "aws_user_pools_id": "us-east-1_emRMZhwrs",
  // "aws_user_pools_web_client_id": "pk841pak66aj59skoo7s3gc17",

  // uat
  // "aws_user_pools_id": "us-east-1_wVqy6Ocmo",
  // "aws_user_pools_web_client_id": "7vqen4singpa2q5bor4amu48ci",

  // prod
  "aws_user_pools_id": "us-east-1_JOledtJa3",
  "aws_user_pools_web_client_id": "45j932jr9si76c3u18eelcsc7o",

  "oauth": {},
  "aws_cognito_username_attributes": ["EMAIL"],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": ["EMAIL"],
  "aws_cognito_mfa_configuration": "ON",
  "aws_cognito_mfa_types": ["TOTP"],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 14,
    "passwordPolicyCharacters": [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  "aws_cognito_verification_mechanisms": ["EMAIL"],
};

export default awsmobile;
