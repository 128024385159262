import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OwnerAccounts from './OwnerAccounts';
import { getWalletAssets, getWalletById } from '../../../store/wallets';
import { Loading } from '../../../assets/icons/loading';
import { currentComponent, showModal } from '../../../store/modal';
import AddAsset from './new/AddAsset';
import ManagerList from './ManagerList';
import { enableWallet, disableWallet } from '../../../store/wallets';
import { toast } from 'react-hot-toast';
import ManageManagers from './admin/ManageManagers';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { getAvailableAssets } from '../../../utils/assets';

function WalletPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [addNetworkModal, setAddNetworkModal] = useState(false);
  const [isToggleLoading, setIsToggleLoading] = useState(false);
  const [availableAssets, setAvailableAssets] = useState([]);

  const wallet = useSelector(state => state?.wallets[id]);
  const walletAssets = useSelector(state => state.wallets.assets);
  const { user } = useSelector(state => state.session);
  const orgId = user.organization;
  const isManager = user.groups.includes('investors');
  const isAdmin = user.groups.includes('admins');
  const isSuperAdmin = user.groups.includes('superadmins');

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getWalletById(controller.signal, id))
      .then(res => {
        if (res?.status >= 300) {
          toast.error(`Failed to get wallet data: ${res.data}`);
          navigate('/dashboard/wallets');
        } else {
          setApiErrors(null);
          setIsLoaded(true);
        }
      })
      .catch(console.error); // TODO: this line always throws an error in strict mode because the function is aborted.
    return () => controller.abort();
  }, [dispatch, id, navigate]);

  useEffect(() => {
    setAvailableAssets(
      getAvailableAssets(walletAssets, wallet?.accounts)
    );
  }, [!!walletAssets, wallet?.accounts])

  useEffect(() => {
    dispatch(
      getWalletAssets({})
    )
      .catch(e => {
        console.log(e)
        toast.error(`Error getting wallet assets: ${e}`);
      });
  }, [])

  const showManagerList = () => {
    dispatch(currentComponent(() => <ManagerList managers={wallet.managers} />));
    dispatch(showModal());
  };

  const addRemoveManagers = () => {
    if (!wallet.active) {
      toast.error('Cannot add managers while the wallet is disabled.');
      navigate('/dashboard/wallets');
      return;
    }
    dispatch(currentComponent(() => <ManageManagers id={id} managers={wallet.managers} />));
    dispatch(showModal());
  };

  const addNewAsset = () => {
    dispatch(currentComponent(() => <AddAsset id={id} />));
    dispatch(showModal());
  };

  const handleToggleWallet = async () => {
    setIsToggleLoading(true);
    toast.dismiss();
    if (!wallet || !orgId) {
      setIsToggleLoading(false);
      return;
    }
    if (wallet.orgId !== orgId) {
      setIsToggleLoading(false);
      toast.error('Not authorized to perform action in another organization.');
      return;
    }

    const load = toast.loading('Saving');
    if (wallet.active) {
      await Promise.all([dispatch(disableWallet(id, orgId))]);
    } else {
      await Promise.all([dispatch(enableWallet(id, orgId))]);
    }
    toast.dismiss(load);
    setIsToggleLoading(false);
    toast.success(`Wallet ${id} ${wallet.active ? 'enabled' : 'disabled'}`);
  };

  if ((!isLoaded && !apiErrors) || !wallet) {
    return <Loading />;
  }

  return (
    <div className="p-10 overflow-none min-w-[600px]">
      <div className='mb-4'>
        <div className="flex flex-row gap-4 mb-4">
          <div><ArrowUturnLeftIcon onClick={() => navigate('/dashboard/wallets')} className="w-6" />
            <div className={`text-red-500 text-md ${wallet.active ? 'hidden' : ''}`}>
              WALLET DISABLED
            </div></div>
        </div>

        <div className="flex">
          <div className="w-[170px] text-gray-400 text-sm">Wallet ID</div>
          <div className="flex-1 text-gray-300 text-sm">{wallet.walletId}</div>
        </div>
        <div className="flex">
          <div className="w-[170px] text-gray-400 text-sm">Wallet Owner</div>
          <div className="flex-1 text-gray-300 text-sm">{wallet.owner}</div>
        </div>
        <div className="flex">
          <div className="w-[170px] text-gray-400 text-sm">Managers</div>
          <div className="flex-1 text-gray-300 text-sm"><span
            onClick={showManagerList}
            className="hover:text-green-500 text-slate-100 cursor-pointer"
          >
            show
          </span></div>
        </div>

        <div className="flex flex-row gap-4 mt-2">
          <button
            className={`p-2 rounded text-white hover:shadow-lg disabled:opacity-50 bg-gradient-to-br from-red-500 to-red-600/75 hover:bg-red-400 ${isAdmin || isSuperAdmin ? '' : 'hidden'}`}
            onClick={handleToggleWallet}
            disabled={!isAdmin && !isSuperAdmin}
          >
            {wallet.active ? 'Disable Wallet' : 'Enable Wallet'}
          </button>
          <button
            className="p-2 rounded text-white hover:shadow-lg disabled:opacity-50 bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400"
            onClick={addNewAsset}
            disabled={!availableAssets.length}
          >
            Add Asset
          </button>
          <button
            className={`p-2 rounded text-white hover:shadow-lg disabled:opacity-50 bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400 ${isManager || isAdmin ? '' : 'hidden'}`}
            onClick={addRemoveManagers}
            disabled={!isManager && !isAdmin}
          >
            {isAdmin ? 'Add/Remove Managers' : 'Add Managers'}
          </button>
          <button
            className={`p-2 rounded text-white hover:shadow-lg disabled:opacity-50 bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400 ${isManager ? '' : 'hidden'}`}
            disabled={!isManager}
            onClick={() => navigate('create')}
          >
            Create Transaction
          </button>
        </div>
      </div>
      <div
        className={`text-white h-full min-h-full shadow-offsetWhite bg-[radial-gradient(ellipse_at_right,_var(--tw-gradient-stops))] from-gray-500/50 to-slate-700/75 backdrop-blur-md rounded w-full ${!wallet.active && '!bg-red-700'
          }`}
      >
        {apiErrors ? (
          <div className="flex flex-col w-full items-center text-white text-sm justify-center py-10">
            Error retrieving wallets: Request timed out.
            <button
              className="inline-flex items-start justify-start px-6 py-4 bg-slate-700 hover:bg-slate-600 focus:outline-none rounded text-white mt-5"
              onClick={() => window.location.reload()}
            >
              Try Again
            </button>
          </div>
        ) : !wallet || !Object.values(wallet.accounts) ? (
          <div className="text-center p-10 text-sm">
            No accounts found for current wallet: {wallet.walletId}
          </div>
        ) : (
          <div className="flex flex-col rounded">
            <OwnerAccounts
              addNetworkModal={addNetworkModal}
              setAddNetworkModal={setAddNetworkModal}
              wallet={wallet}
              isToggleLoading={isToggleLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default WalletPage;
