import { useEffect, useMemo } from 'react';

import { NetworkLogo } from '../../../assets/networks';
import { Loading } from '../../../assets/icons/loading';
import AddAsset from './new/AddAsset';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances, getWalletById, hideAsset } from '../../../store/wallets';
import { toast } from 'react-hot-toast';

const OwnerAccounts = ({ addNetworkModal, setAddNetworkModal, wallet }) => {
  const { walletId } = wallet;

  const balances = useSelector(state => state?.wallets[walletId]?.balances);
  const accounts = useSelector(state => {
    if (state?.wallets[walletId]?.accounts) {
      return Object.keys(state?.wallets[walletId]?.accounts).map(network => ({
        ...state?.wallets[walletId]?.accounts[network],
        network,
      }));
    }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const getData = async () => {
      try {
        const res = await dispatch(getWalletById(controller.signal, walletId));
        if (res && res.accounts) {
          await dispatch(getBalances(controller.signal, { walletId }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
    return () => {
      controller.abort();
    };
  }, [walletId, dispatch]);

  const showNetworkIcon = (network, token) => <NetworkLogo network={network} token={token} size="m" />;

  const handleHideAsset = async (network, asset) => {
    toast.dismiss();
    toast.loading('Working...');

    await dispatch(hideAsset({ walletId, network, asset }));
    toast.dismiss();
  };

  return useMemo(() => {
    if (!wallet)
      return (
        <div className="p-10">
          <Loading />
        </div>
      );

    const totalTokens = accounts.reduce((acc, acct) => acc + acct.assets.length ,0)

    return (
      <>
        <div key={walletId} className="flex flex-col w-full mb-5 text-white">
          <div className="p-8 rounded-lg mt-5">
            {accounts?.length > 0 ? (
              accounts.map((acct, i) => (
                acct.assets.map((asset, i) => (
                  <div
                    key={acct.address + asset}
                    className={`${
                      i !== totalTokens - 1 && 'mb-2'
                    } bg-gradient-to-br from-slate-700 to-stone-800/75 rounded relative overflow-hidden ${
                      !acct.active && 'bg-red-500'
                    }`}
                  >
                    {showNetworkIcon(acct.token, asset)}
                    <div className="flex flex-col justify-center h-[140px] text-white rounded-t">
                      <div className="relative flex justify-end text-sm">
                        <div className="text-xl font-bold opacity-75 pr-5">
                          <div className="flex justify-end flex-col items-end">
                              <div>{asset.toUpperCase()}</div>
                              {!acct.active && <div className="text-sm text-red-500">Disabled</div>}
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-col w-full flex items-center justify-center rounded-b">
                      <div
                        className="h-[140px] bg-black opacity-70 w-full p-5 transition duration-700 ease-in-out origin-bottom transform absolute top-0 z-50"
                      >
                        <div className="float-right">
                          <div className="mb-8">

                            <button
                              className="text-xs mr-5 flex items-center border rounded p-1 hover:text-red-500 hover:border-red-500 duration-200"
                              onClick={() => handleHideAsset(acct.network, asset)}
                            >
                              Hide Asset
                            </button>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-[180px] pl-52">Status</div>
                          <div className="w-[400px] pl-32">
                            <div className={acct.active || 'hidden'}>
                                  <span className={`text-green-500 ${wallet.active ? '' : 'hidden'}`}>
                                    Active
                                  </span>
                              <span className={`text-red-500 ${wallet.active ? 'hidden' : ''}`}>
                                    Wallet Disabled
                                  </span>
                            </div>
                            <span className={`text-red-500 ${acct.active ? 'hidden' : ''}`}>
                                  Account Disabled
                                </span>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-[180px] pl-52">Address</div>
                          <div className="pl-32">{acct.address}</div>
                        </div>
                        <div className="flex">
                          <div className="w-[180px] pl-52">Balance</div>
                          <div className="pl-32">
                            <TokenBalance asset={asset} walletId={walletId} balances={balances} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ))
            ) : (
              <div className="text-center my-5 text-sm">
                No accounts added under this Wallet ID: {walletId}
              </div>
            )}
          </div>
        </div>
        {addNetworkModal && <AddAsset wallet={wallet} setAddNetworkModal={setAddNetworkModal} />}
      </>
    );
  }, [wallet, balances]);
};

function TokenBalance({ asset, walletId, balances }) {
  return useMemo(() => {
    if (!balances) {
      return <div className="h-36 text-yellow-300 animate-pulse">Loading...</div>;
    }

    if (balances.error) {
      return <div className="h-36 text-yellow-300">No Balance Available</div>;
    }

    const bal = balances.find(x => x.asset === asset);
    if (!bal) {
      return null;
    }
    return <Balance balance={bal.balance} asset={asset} />;
  }, [asset, walletId, balances])
}

function Balance({ balance, asset }) {
  return <span>{`${balance} ${asset.toUpperCase()}`}</span>;
}

export default OwnerAccounts;
