import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { removeUserSession } from '../../../store/session';
import IdlePrompt from './IdlePrompt';
import { toast } from 'react-hot-toast';

const SessionTimeout = ({ props }) => {
  const { minimized, setMinimized } = props;
  // IDLE TIMER
  const [remaining, setRemaining] = useState(useIdleTimer.timeout);
  const [showPrompt, setShowPrompt] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onIdle = () => {
    Promise.resolve(dispatch(removeUserSession()))
      .then(() => {
        toast.dismiss();
        navigate('/login');
        toast.success('You have been logged out due to inactivity.');
      })
      .catch(e => console.error(`Error with logout: ${e}`));
  };

  const onPrompt = () => {
    setMinimized(false);
    setShowPrompt(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    promptBeforeIdle: 30 * 1000, // 30 seconds
    timeout: 10 * 60 * 1000, // 10 minutes
    throttle: 500,
  });
  const handleActivate = () => {
    setShowPrompt(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => clearInterval(interval);
  });

  if (!showPrompt) {
    return null;
  }

  return (
    <div
      className={`flex flex-col ${
        minimized
          ? '!min-w-[100px] !w-100 !max-w-[100px]'
          : '!w-[240px] !min-w-[240px] !max-w-[240px]'
      } transition-all transform duration-1000 delay-300 mt-5`}
    >
      <div
        className={
          minimized
            ? 'opacity-0 absolute -transform-x-24'
            : 'delay-[1000ms] duration-500 w-full text-center text-xs transform-x-0'
        }
      >
        {<IdlePrompt props={{ showPrompt, remaining, handleActivate }} />}
      </div>
    </div>
  );
};

export default SessionTimeout;
