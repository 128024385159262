import { BuildingLibraryIcon } from '@heroicons/react/24/outline';

export const AdminAccessBanner = ({ title = 'Admin' }) => (
  <div className="flex text-green-500">
    <BuildingLibraryIcon className="w-6 pr-2" />
    <span>{title}</span>
  </div>
);

export function adminAccessBanner(user) {
  if (user.groups.includes('superadmins')) {
    return <AdminAccessBanner title="SUPER ADMIN" />;
  }
  if (user.groups.includes('admins')) {
    return <AdminAccessBanner />;
  }
  if (user.groups.includes('developers')) {
    return <AdminAccessBanner title="DEVELOPER" />;
  }
  return null;
}
