import { useEffect, useState } from 'react';
import { baseCard } from '../../../config/tailwind.classnames';
import apiInstructions from './etana-digital-api.md';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';

import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { Loading } from '../../../assets/icons/loading';

SyntaxHighlighter.registerLanguage('markdown', markdown);
SyntaxHighlighter.registerLanguage('json', json);

const Instructions = () => {
  const [file, setFile] = useState('');
  useEffect(() => {
    fetch(apiInstructions)
      .then(res => res.text())
      .then(md => setFile(md));
  }, []);

  return (
    <div className="p-10 h-0 min-h-[100%]">
      <h1 className="text-2xl pb-8">API Instructions</h1>
      <div className={`${baseCard} overflow-auto h-0 min-h-[90%]`}>
        {file ? (
          <ReactMarkdown
            children={file}
            remarkPlugins={[remarkGfm]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    // @ts-ignore
                    style={coldarkDark}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
              h1: ({ children }) => (
                <h2 className="text-white text-3xl font-bold mb-5 mt-3">{children}</h2>
              ),
              h2: ({ children }) => (
                <h2 className="text-white text-2xl font-bold mb-5 mt-3">{children}</h2>
              ),
              p: ({ children }) => <p className="text-sm font-bold mb-5 mt-3">{children}</p>,
            }}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Instructions;
