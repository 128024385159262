import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import useClickOutside from '../../../../hooks/useClickOutside';

export type FilterEntriesProps = {
  filter: any;
  setFilter: (filter: any | ((curr: any) => void)) => void;
  jump: (page: number) => void;
  deposits: { asset: string; seenAt: string; }[];
};

export const FilterEntries = ({ filter, setFilter, jump, deposits }: FilterEntriesProps) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isFilterFull, setIsFilterFull] = useState(true);
  const [isFilterEmpty, setIsFilterEmpty] = useState(false);
  const filterWrapperRef = useRef<HTMLDivElement>(null);

  const dateOptions = [
    { value: '24h', label: 'Last 24 hours' },
    { value: '7d', label: 'Last 7 days' },
    { value: '30d', label: 'Last 30 days' },
    { value: 'all', label: 'All Time' },
  ];

  useEffect(() => {
    setIsFilterFull(isDefaultFilter());
    setIsFilterEmpty(isEmptyFilter());
    jump(1);
  }, [filter]);

  useEffect(() => {
    if (deposits && Array.isArray(deposits)) {
      const initialFilterState = {
        networks: {
          bitcoin: false,
          ethereum: false,
          avalanche: false,
          polymesh: false,
        },
        seenAt: '',
      };
      setFilter((prev: any) => ({
        ...prev,
        networks: initialFilterState.networks,
        seenAt: initialFilterState.seenAt
      }));
    }
  }, [deposits]);

  const showNothing = {
    networks: {
      bitcoin: false,
      ethereum: false,
      avalanche: false,
      polymesh: false,
    },
    seenAt: '',
  };

  const isDefaultFilter = () => {
    const { networks, seenAt } = filter;
    return Object.values(networks).every(Boolean) && seenAt === '';
  };

  const isEmptyFilter = () => {
    const { networks, seenAt } = filter;
    return Object.values(networks).every(v => !v) && seenAt === '';
  };

  const clearAll = () => {
    if (isFilterEmpty) return;
    setFilter({
      ...showNothing,
      networks: { ...showNothing.networks },
      seenAt: showNothing.seenAt,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFilter((prev: any) => ({
      ...prev,
      networks: { ...prev.networks, [name]: checked }
    }));
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter((prev: any) => ({
      ...prev,
      seenAt: e.target.value
    }));
  };

  const handleOpenMenu = () => setFilterMenuOpen(true);
  const handleCloseMenu = () => setFilterMenuOpen(false);

  useClickOutside(filterWrapperRef, handleCloseMenu);

  const selectors = 'w-full rounded text-white text-sm h-8 mr-2';
  const labelText = 'flex text-white items-center text-sm';

  return (
    <div
      className="relative self-center text-white group h-fit"
      ref={filterWrapperRef}
    >
      <button
        onClick={filterMenuOpen ? handleCloseMenu : handleOpenMenu}
        className={`${filterMenuOpen ? 'bg-gray-700 rounded-t' : 'rounded'
          } group-hover:text-green-600 flex items-center text-sm`}
      >
        <span>
          <FunnelIcon className="w-4" />
        </span>
        <span className="pl-2">Filter</span>
      </button>
      {filterMenuOpen && (
        <div className="absolute right-0 z-30 p-5 transition-opacity duration-500 rounded-b rounded-l shadow-2xl group bg-gradient-to-r from-slate-500 to-slate-700 w-128">
          <div className="w-full mt-2 mb-2">
            <div className="flex flex-col w-full">
              <div className="mb-2 text-sm font-bold text-left">Networks</div>
              <div className="flex justify-between w-full">
                {['bitcoin', 'ethereum', 'avalanche', 'polymesh'].map(network => (
                  <label className={labelText} key={network}>
                    <input
                      type="checkbox"
                      className={selectors}
                      name={network}
                      onChange={handleChange}
                      checked={filter.networks[network]}
                    />
                    {network.charAt(0).toUpperCase() + network.slice(1)}
                  </label>
                ))}
              </div>
            </div>

            <div className="flex flex-col w-full mt-4">
              <div className="mb-2 text-sm font-bold text-left">Date Seen</div>
              <div className="flex flex-wrap space-x-4">
                {dateOptions.map(option => (
                  <label className="flex items-center text-sm space-x-2" key={option.value}>
                    <input
                      type="radio"
                      className="h-3 w-3"
                      name="dateSeen"
                      value={option.value}
                      onChange={handleDateChange}
                      checked={filter.seenAt === option.value}
                    />
                    <span>{option.label}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="flex justify-end pt-5">
              <div
                className={
                  !isFilterEmpty
                    ? 'text-white text-sm cursor-pointer hover:text-green-600 w-fit ml-5'
                    : 'text-gray-500 text-sm w-fit cursor-not-allowed select-none ml-5'
                }
                onClick={clearAll}
              >
                Clear All
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterEntries;
