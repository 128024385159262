import { NetworkLogo } from './networks';
import { formatIntegerAmount, nativeTokenByNetwork } from '../utils/networkTools';

interface AssetAmountProps {
  asset: string;
  network: string;
  amount: number;
  formatAmountFunction?: (amount: string | number, asset: string) => string | null;
}
export function AssetAmount({
  asset,
  network,
  amount,
  formatAmountFunction = formatIntegerAmount,
}: AssetAmountProps) {
  return (
    <div className="flex items-center space-x-2">
      <span className="w-6">
        <NetworkLogo network={nativeTokenByNetwork(network)} token={asset} size="s" />
      </span>
      <span>
        {formatAmountFunction ? formatAmountFunction(amount, asset) : amount} {asset.toUpperCase()}
      </span>
    </div>
  );
}