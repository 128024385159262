import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { baseCard } from '../../../config/tailwind.classnames';
import usePaginate from '../../../hooks/usePaginate';
import PageNavigation from '../../../utils/PageNavigation';
import { Loading } from '../../../assets/icons/loading';
import WalletListItem from './WalletListItem';
import { getWallets } from '../../../store/wallets.js';
import Select from 'react-select';
import { base } from '../../../config/select.styles';
import { createSelector } from '@reduxjs/toolkit';
import WalletsHeader from './WalletsHeader';
import { PlusIcon } from '@heroicons/react/24/outline';
import { adminAccessBanner } from '../../misc/AdminAccessBanner';

const WalletList = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [apiErrors, setAPIErrors] = useState(null);
  const [searchCategory, setSearchCategory] = useState({ label: 'Wallet ID', key: 'walletId' });
  const [searchData, setSearchData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [walletData, setWalletData] = useState([]);

  const [disabled, setDisabled] = useState({ show: false, count: 0 });

  const [organizationVisibility, setOrganizationVisibility] = useState({
    ETANATRUST: true,
    BDACS: true,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const errorRef = useRef(null);

  const { user } = useSelector(state => state.session);
  const isSuperAdmin = user.groups.includes('superadmins');
  const isAdmin = user.groups.includes('admins');
  const isManager = user.groups.includes('investors');

  const walletState = createSelector(
    [state => state.wallets, state => Object.values(state)],
    (_, state) => state,
  );

  const wallets = walletState(useSelector(state => state.wallets));

  const { prev, jump, next, page } = usePaginate(searchQuery ? searchData : walletData || [], 20);
  const [displayPageNumber, setDisplayPageNumber] = useState(page.current);

  useEffect(() => jump(displayPageNumber), [displayPageNumber, jump]);

  // Get wallets and store in Redux
  useEffect(() => {
    const controller = new AbortController();
    dispatch(getWallets(controller.signal))
      .then(res => {
        if (Array.isArray(res)) {
          const sortedData = res.sort((a, b) => b.active - a.active);
          setWalletData(sortedData);
        }
      })
      .then(() => clearTimeout(errorRef.current))
      .catch(err => setAPIErrors(err))
      .finally(() => setIsLoaded(true));

    return () => {
      controller.abort();
      setSearchQuery('');
      setIsLoaded(false);
      setAPIErrors(null);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!wallets || !walletData || !searchQuery) return;
    // Filter given a query and a category
    const arr = walletData?.filter(wallet => {
      if (!wallet) return false;

      // Searching by whatever data is set as the search category
      const keyValue = wallet[searchCategory.key];

      let category;
      if (typeof keyValue === 'string') category = keyValue.toLowerCase();
      // Managers are in an array and must be joined into a string to be searched the same way as the other data
      if (typeof keyValue === 'object') category = keyValue.join(', ').toLowerCase();
      const query = searchQuery?.toLowerCase();

      if (!category?.includes(query)) return false;
      if (typeof keyValue === 'object' && query.length) {
        const idx = keyValue.findIndex(manager => manager.includes(query));
        if (idx > 0) {
          // if the queried item is not at the front, splice it (remove it) and place in the front
          const managerInQuery = keyValue.splice(idx, 1);
          keyValue.unshift(managerInQuery);
        }
      }

      return true;
    });
    // Jump to first page so a user isn't stuck on a page that no longer exists
    jump(1);
    setSearchData(arr);
  }, [jump, searchCategory.key, walletData, wallets, searchQuery]);

  if (!isLoaded || !wallets) return <Loading />;

  const handleSelect = e => {
    setSearchQuery('');
    setSearchCategory(e);
  };

  const pageNavigation = (
    <PageNavigation
      prev={prev}
      jump={jump}
      next={next}
      curr={page.current}
      max={page.max}
      displayPageNumber={displayPageNumber}
      setDisplayPageNumber={setDisplayPageNumber}
    />
  );

  return (
    <div className="xl:px-28">
      {/*Header*/}
      <div className="flex items-center justify-between pb-5 mb-5 min-w-fit">
        <div>
          <div className="flex items-center">
            <span className="text-2xl text-gray-100 font-montserratM pr-4">Wallets</span>
            <span>{adminAccessBanner(user)}</span>
          </div>
          <WalletsHeader
            props={{
              disabled,
              setDisabled,
              organizationVisibility,
              setOrganizationVisibility,
            }}
          />
        </div>
        <div>
          <button
            className={`rounded text-white hover:shadow-lg bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400 ${!isManager ? 'hidden' : ''}`}
            onClick={() => navigate('/dashboard/wallets/new')}
          >
            <span className="flex p-2">
              <PlusIcon className="w-6 pr-1" />
              Create New Wallet
            </span>
          </button>
        </div>
      </div>

      {/*Search*/}
      <form onSubmit={e => e.preventDefault()} className="pb-5 text-sm flex h-10 items-center">
        <div className="flex">
          <span className="min-w-[80px] flex items-center">Search by</span>
          <div className="pr-3">
            <Select
              className="w-36 hover:bg-gray-600"
              defaultValue={{ label: 'Wallet ID', name: 'Wallet ID' }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              options={[
                { label: 'Wallet ID', key: 'walletId' },
                { label: 'Owner', key: 'owner' },
                // { label: 'Managers', key: 'managers' }, // TODO
              ]}
              placeholder="select prefix"
              onChange={handleSelect}
              styles={base}
            />
          </div>
        </div>
        <input
          className="w-full bg-transparent border-b p-1 focus:outline-none"
          placeholder={searchCategory.label}
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </form>

      {/*Wallets*/}
      <div className={`${baseCard} ${(isAdmin || isSuperAdmin) && 'border-2 border-green-500'}`}>
        {walletData?.length ? (
          <>
            {pageNavigation}
            <table className="w-full">
              <thead>
                <tr className="py-5 text-sm text-white text-left">
                  <th>
                    <span>Wallet ID</span>
                  </th>
                  <th>
                    <span>Owner</span>
                  </th>
                  <th>
                    <span>Managers</span>
                  </th>
                  {/*show assets here*/}
                  <th className="text-center">
                    <span>Assets</span>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full">
                {page.data()?.map((wallet, i) => (
                  <WalletListItem
                    key={i}
                    data={wallet}
                    category={searchCategory}
                    query={searchQuery}
                  />
                ))}
              </tbody>
            </table>
            {searchQuery && !searchData?.length ? (
              <div className="flex w-full items-center text-white text-sm justify-center py-4">
                No wallets matched your search criteria.
              </div>
            ) : (
              <>
                <div>
                  <div className="flex w-full items-center text-white text-sm justify-center py-4">
                    {page.from} - {page.to} of{' '}
                    {searchQuery ? searchData?.length : walletData?.length} wallets
                  </div>
                  {pageNavigation}
                </div>
              </>
            )}
          </>
        ) : apiErrors ? (
          <div className="flex flex-col w-full items-center text-white text-sm justify-center py-4 pt-8">
            Error retrieving wallets: Request timed out.
            <button
              className="inline-flex items-start justify-start px-6 py-4 bg-slate-700 hover:bg-slate-600 focus:outline-none rounded text-white mt-5"
              onClick={() => window.location.reload()}
            >
              Try Again
            </button>
          </div>
        ) : (
          <div className="flex w-full items-center text-white text-sm justify-center py-4 pt-8">
            No wallets
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletList;
