import { organizations } from '../config';
import { icon } from '../config/tailwind.classnames';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

type OrganizationFilterProps = {
  hidden: boolean;
  organizationVisibility: { ETANATRUST: boolean; BDACS: boolean };
  setOrganizationVisibility: (fn: Function) => void;
};

const OrganizationFilter = (props: OrganizationFilterProps) => {
  if (props.hidden) {
    return null;
  }

  const { organizationVisibility, setOrganizationVisibility } = props;
  return (
    <div className="my-2 rounded bg-black text-sm border-2 p-3 border-red-500 bg-opacity-50 w-full flex justify-between items-center">
      <div className="w-[200px]">Toggle Visibility</div>
      <div className="flex w-full">
        {organizations.map(org => (
          <div
            key={org.id}
            className={
              organizationVisibility[org.id as keyof typeof organizationVisibility]
                ? icon.visible
                : icon.invisible
            }
            onClick={() =>
              setOrganizationVisibility((prev: { ETANATRUST: boolean; BDACS: boolean }) => ({
                ...prev,
                [org.id]: !prev[org.id as keyof typeof organizationVisibility],
              }))
            }
          >
            <span className="mr-2">
              {organizationVisibility[org.id as keyof typeof organizationVisibility] ? (
                <EyeIcon className="w-4" />
              ) : (
                <EyeSlashIcon className="w-4" />
              )}
            </span>
            <span>{org.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrganizationFilter;
