export const getAssetsList = (walletAssets: any[] | undefined) => {
  return walletAssets?.reduce((acc, asset) => {
    acc.push(...asset.data.map((assetData: any) => ({
      network: asset.network,
      ...assetData,
    })));
    return acc;
  }, []);
}
export const getAvailableAssets = (walletAssets: any[] | undefined, accounts: Record<string, any>) => {
  const assets = getAssetsList(walletAssets);
  return assets?.filter((assetData: any) =>
    !accounts?.[assetData.network]?.assets.includes(assetData.asset)
  ) || [];
}