import axiosRequest from './axios';
import { urls } from '../config';
import {
  ADD_MGR_TO_WALLET, ASSETS_WALLET,
  CREATE_WALLET,
  DISABLE_ACCOUNT_IN_WALLET,
  DISABLE_WALLET,
  ENABLE_ACCOUNT_IN_WALLET,
  ENABLE_WALLET,
  GET_BALANCES,
  GET_ONE_WALLET,
  GET_WALLETS,
  REM_MGR_FM_WALLET
} from './constants';
import { formatIntegerAmount } from '../utils/networkTools';

// ACTION CREATORS
const newWallet = data => ({ type: CREATE_WALLET, data });
const setWalletAssets = data => ({ type: ASSETS_WALLET, data });
const setWallets = data => ({ type: GET_WALLETS, data });
const setOneWallet = data => ({ type: GET_ONE_WALLET, data });
const setBalances = (data, walletId) => ({ type: GET_BALANCES, data, walletId });
const addManagerToWallet = (walletId, managers) => ({
  type: ADD_MGR_TO_WALLET,
  walletId,
  managers,
});
const removeManagerFromWallet = (walletId, managers) => ({
  type: REM_MGR_FM_WALLET,
  walletId,
  managers,
});
const setEnableWallet = walletId => ({ type: ENABLE_WALLET, walletId });
const setDisableWallet = walletId => ({ type: DISABLE_WALLET, walletId });

const setEnableAccount = (walletId, network) => ({
  type: ENABLE_ACCOUNT_IN_WALLET,
  walletId,
  network,
});

const setDisableAccount = (walletId, network) => ({
  type: DISABLE_ACCOUNT_IN_WALLET,
  walletId,
  network,
});

// THUNKS
export const createNewWallet = data => async dispatch => {
  const response = await axiosRequest(urls.createWallet, {
    method: 'POST',
    data,
  });

  if (response?.error) return [response];
  await dispatch(newWallet(response.data));
  return response.data;
};

export const getWalletAssets = data => async dispatch => {
  const response = await axiosRequest(urls.walletAssets, {
    method: 'GET',
    data,
  });

  if (response?.error) return [response];
  await dispatch(setWalletAssets(response.data));
  return response.data;
};

export const getWalletById = (signal, id) => async (dispatch, getState) => {
  if (!id) return;

  const { role, user } = await getState()?.session;
  const res = await axiosRequest(`${urls.walletById}/${id}`, { signal });
  if (!res) return;

  const { status, data } = res;

  if (status >= 300) return { status, error: data };
  const forbidden = { status: 403, error: 'Forbidden' };
  switch (role) {
    case 'investors':
      if (data.orgId !== user.organization || !data.managers.includes(user.email)) return forbidden;
      break;
    case 'admins':
      if (data.orgId !== user.organization) return forbidden;
      break;
    case 'superadmins':
      break;
    default:
      return forbidden;
  }
  await dispatch(setOneWallet(data));
  return data;
};

/// SUPER ADMIN

export const getWalletsAsSuperAdmin = signal => async (dispatch, getState) => {
  const { role } = await getState()?.session;
  if (!role || role !== 'superadmins') return;

  const res = await axiosRequest(`${urls.superAdminUrl}/wallets`, { signal });

  if (!res) return;
  if (res.status >= 300) return { error: res.data };

  if (res.data) {
    await dispatch(setWallets(res.data || []));
    return res.data;
  }
};

export const getWallets = signal => async (dispatch, getState) => {
  const sess = await getState().session;

  let res;
  switch (sess?.role) {
    case 'investors':
      res = await axiosRequest(urls.walletUrl, { signal });
      break;
    case 'admins':
      res = await axiosRequest(`${urls.adminUrl}/wallets`, { signal });
      break;
    case 'superadmins':
      res = await axiosRequest(`${urls.superAdminUrl}/wallets`, { signal });
      break;
    default:
      throw new Error(`Unknown role ${sess?.role}`);
  }

  if (!res) {
    return;
  }
  if (res.status >= 300) {
    return { error: res.data };
  }

  if (res.data) {
    await dispatch(setWallets(res.data));
    return res.data;
  }
};

export const getBalances = (signal, id) => async dispatch => {
  try {
    const res = await axiosRequest(`${urls.walletBalanceUrl}/${id.walletId}`, {
      method: 'GET',
      timeout: 30000,
      signal,
    });
    if (res?.error) return [res];
    if (res && res.data) {
      const output = res.data.data.reduce((acc, bal) => {
        const network = bal.network;
        const balance = bal.balance;
        const decimals = bal.decimals;
        const asset = bal.asset;
        acc.push({
          asset: asset.toLowerCase(),
          network,
          balance: formatIntegerAmount(balance, asset, decimals),
        });
        return acc;
      }, []);
      await dispatch(setBalances(output, id));
      return output;
    }
  } catch (error) {
    return { error: 'An error occurred while fetching balances' };
  }
};

export const addAsset = data => async dispatch => {
  const response = await axiosRequest(urls.walletAddAsset, { method: 'POST', data });
  await dispatch(setOneWallet(response.data));
  return response.data;
};

export const hideAsset = data => async dispatch => {
  const response = await axiosRequest(urls.walletHideAsset, { method: 'POST', data });
  await dispatch(setOneWallet(response.data));
  return response.data;
};

export const addManagerToExistingWallet = (walletId, manager) => async dispatch => {
  const res = await axiosRequest(`${urls.apiUrl}/wallets/add-managers`, {
    method: 'POST',
    data: { walletId, managers: [manager] },
  });

  if (!res)
    return {
      status: 424, // Failed dependency
      error: 'failed to make POST request to digital wallet service',
    };
  if (res.status >= 300) return { status: res.status, error: res.data };

  await dispatch(addManagerToWallet(walletId, manager));
  return res.data;
};

export const adminAddManagerToWallet = (walletId, manager) => async dispatch => {
  const res = await axiosRequest(`${urls.adminUrl}/wallets/${walletId}/add-manager`, {
    method: 'POST',
    data: { managers: [manager] },
  });

  if (!res)
    return {
      status: 424, // Failed dependency
      error: 'failed to make POST request to digital wallet service',
    };
  if (res.status >= 300) return { status: res.status, error: res.data };

  await dispatch(addManagerToWallet(walletId, manager));
  return res.data;
};

export const removeManagerFromExistingWallet = (walletId, managers) => async dispatch => {
  const response = await axiosRequest(`${urls.adminUrl}/wallets/${walletId}/remove-manager`, {
    method: 'POST',
    data: { managers: [managers] },
  });

  if (!response) return;

  if (response.status < 300) {
    await dispatch(removeManagerFromWallet(walletId, managers));
    return response.data;
  } else {
    return { error: response.data };
  }
};

export const enableWallet = (walletId, orgId) => async dispatch => {
  const res = await axiosRequest(`${urls.adminUrl}/wallets/${walletId}/enable`, {
    method: 'POST',
    data: { orgId },
  });
  if (!res) return { error: 'no response' };
  if (res.status >= 300) return { error: res.data, status: res.status };
  await dispatch(setEnableWallet(walletId));
  return res.data;
};

export const disableWallet = (walletId, orgId) => async dispatch => {
  const res = await axiosRequest(`${urls.adminUrl}/wallets/${walletId}/disable`, {
    method: 'POST',
    data: { orgId },
  });

  if (!res) return { error: 'no response', status: 404 };
  if (res.status >= 300) return { error: res.data, status: res.status };
  await dispatch(setDisableWallet(walletId));
  return res.data;
};

// -^- ADMIN ACCESS -^- //

// REDUCER
const walletReducer = (state = {}, action) => {
  const newState = { ...state };
  switch (action.type) {
    case CREATE_WALLET: {
      if (action.data) newState[action.data.walletId] = action.data;
      return newState;
    }
    case GET_WALLETS: {
      if (action.data) action.data.forEach(wallet => (newState[wallet.walletId] = wallet));
      return newState;
    }
    case ASSETS_WALLET:
      if (action.data) newState['assets'] = action.data;
      return newState;
    case GET_ONE_WALLET: {
      let bal;
      if (action.data) {
        if (newState[action.data.walletId] && newState[action.data.walletId].balances)
          bal = newState[action.data.walletId].balances;

        newState[action.data.walletId] = action.data;

        if (bal && newState[action.data.walletId]) {
          newState[action.data.walletId].balances = bal;
        }
      }
      return newState;
    }
    case GET_BALANCES: {
      if (action.data && action.walletId.walletId)
        newState[action.walletId.walletId].balances = action.data;
      return newState;
    }
    case ADD_MGR_TO_WALLET: {
      if (action.data && newState[action.data.walletId]) {
        newState[action.data.walletId].managers.push(action.data.managers);
      }
      return newState;
    }
    case REM_MGR_FM_WALLET: {
      if (action.data && newState[action.data.walletId]) {
        const idx = newState[action.data.walletId].managers.indexOf(action.data.managers);
        newState[action.data.walletId].managers.splice(idx, 1);
      }
      return newState;
    }
    case ENABLE_WALLET: {
      if (action.walletId && newState[action.walletId]) {
        newState[action.walletId].active = true;
      }
      return newState;
    }
    case DISABLE_WALLET: {
      if (action.walletId && newState[action.walletId]) {
        newState[action.walletId].active = false;
      }
      return newState;
    }
    case ENABLE_ACCOUNT_IN_WALLET: {
      if (action.walletId && action.network && newState[action.walletId]) {
        newState[action.walletId].accounts[action.network].active = true;
      }
      return newState;
    }
    case DISABLE_ACCOUNT_IN_WALLET: {
      if (action.walletId && action.network && newState[action.walletId]) {
        newState[action.walletId].accounts[action.network].active = false;
      }
      return newState;
    }
    default:
      return state;
  }
};

export default walletReducer;
