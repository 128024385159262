import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSort from '../../../hooks/useSort';
import usePaginate from '../../../hooks/usePaginate';
import PageNavigation from '../../../utils/PageNavigation';
import { Loading } from '../../../assets/icons/loading';
import { baseCard } from '../../../config/tailwind.classnames';

import TransactionsHeader from './TransactionsHeader';
import TransactionListItem from './TransactionListItem';
import axiosRequest from '../../../store/axios';
import { getWalletsAsSuperAdmin } from '../../../store/wallets';
import { urls } from '../../../config';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import Select from 'react-select';
import { base } from '../../../config/select.styles';
import FilterEntries from './actions/FilterEntries';

/**
 * @description - Get transactions by calling the API. Just return the data, or throw if the call errors for some reason.
 * @throws
 * @param {*} user - the user (from the redux cache)
 * @param {String} role - the user's preferred role
 * @returns {Promise<*[]>} - a slice of transactions
 */
async function getTransactions(user, role) {
  let url;
  switch (role) {
    case 'superadmins':
      url = `${urls.superAdminUrl}/transactions`;
      break;
    case 'admins':
      url = `${urls.adminUrl}/transactions`;
      break;
    default:
      url = urls.transactionUrl;
  }
  const controller = new AbortController();
  const opts = { signal: controller.signal };
  const res = await axiosRequest(url, opts);
  if (!res || !res.status) throw new Error('No response');
  if (res.status >= 300) throw new Error(`Error status ${res.status}`);
  return res.data;
}

function TransactionList() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeSort, setActiveSort] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCategory, setSearchCategory] = useState({ label: 'Wallet ID', key: 'walletId' });

  // Redux
  const dispatch = useDispatch();
  const wallets = useSelector(state => state.wallets);
  const { user, role } = useSelector(state => state.session);

  // Sort and paginate transactions. Allow jumping to pages by page number.
  const { sortedItems, requestSort, sortConfig } = useSort(filteredTransactions);
  const { prev, jump, next, page } = usePaginate(sortedItems, 20);
  const [displayPageNumber, setDisplayPageNumber] = useState(page.current);

  const [filter, setFilter] = useState({
    networks: {
      bitcoin: false,
      ethereum: false,
      avalanche: false,
      polymesh: false,
    },
    approvalStatus: {
      approved: false,
      pending: false,
      rejected: false,
    },
  });
  const [organizationVisibility, setOrganizationVisibility] = useState({
    ETANATRUST: true,
    BDACS: true,
  });

  useEffect(() => jump(displayPageNumber), [displayPageNumber, jump]);

  // Get transactions from API, which just queries the database
  useEffect(() => {
    getTransactions(user, role)
      .then(txs => setTransactions(txs || []))
      .catch(() => setApiError(true))
      .finally(() => setIsLoaded(true));
  }, [user, role]);

  // Update wallets (important for filter below)
  useEffect(() => {
    if (role !== 'superadmins') return;
    const controller = new AbortController();
    dispatch(getWalletsAsSuperAdmin(controller.signal));
  });

  // Filter data based on search query, network, and approval status
  useEffect(() => {
    // Filter transactions based on search query, network, and approval status
    const filteredData = Object.values(transactions)?.filter(item => {
      // Check if any network filter option is true
      const anyNetworkFilterTrue = Object.values(filter.networks).some(value => value === true);
      // Check if any approval status filter option is true
      const anyApprovalStatusTrue = Object.values(filter.approvalStatus).some(
        value => value === true,
      );

      // Check if item matches search query or if no search query is provided
      const matchesSearchQuery =
        !searchQuery ||
        (searchCategory.key === 'walletId' && item.walletId.includes(searchQuery)) ||
        (searchCategory.key === 'txId' && item.txId.includes(searchQuery));

      // Apply network and approval status filters
      let passesNetworkFilter = true;
      let passesApprovalStatusFilter = true;

      // Apply network filter if any network filter is enabled
      if (anyNetworkFilterTrue) {
        passesNetworkFilter = filter.networks[item.network];
      }

      // Apply approval status filter if any approval status filter is enabled
      if (anyApprovalStatusTrue) {
        passesApprovalStatusFilter = filter.approvalStatus[item.approval.status];
      }

      // Return true only if item matches search query and passes network and approval status filters
      return matchesSearchQuery && passesNetworkFilter && passesApprovalStatusFilter;
    });
    setFilteredTransactions(filteredData);
  }, [filter, transactions, wallets, organizationVisibility, role, searchQuery, searchCategory]);

  const getClassNamesFor = name => {
    if (!sortConfig) return;
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  const sortBy = key => setActiveSort(`${key}--${requestSort(key)}`);

  const showCaret = name => {
    if (!activeSort) return;
    const [key, direction] = activeSort.split('--');
    if (name !== key) return;

    if (direction === 'ascending') return <ChevronUpIcon className="w-3" />;
    if (direction === 'descending') return <ChevronDownIcon className="w-3" />;
  };

  const pageNavigation = (
    <PageNavigation
      prev={prev}
      jump={jump}
      next={next}
      curr={page.current}
      max={page.max}
      displayPageNumber={displayPageNumber}
      setDisplayPageNumber={setDisplayPageNumber}
    />
  );

  const handleSearchInputChange = e => {
    setSearchQuery(e.target.value);
  };
  const handleSelect = e => {
    setSearchQuery('');
    setSearchCategory(e);
  };

  return (
    <div className="xl:px-28 min-w-[600px]">
      <TransactionsHeader
        transactions={transactions}
        organizationVisibility={organizationVisibility}
        setOrganizationVisibility={setOrganizationVisibility}
        filter={filter}
        setFilter={setFilter}
        jump={jump}
      />
      {/* Search */}
      <div className="flex py-3">
        <span className="min-w-[80px] flex items-center">Search by</span>
        <div className="pr-3">
          <Select
            className="w-36 hover:bg-gray-600 ml-4"
            defaultValue={{ label: 'Wallet ID', name: 'Wallet ID' }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={[
              { label: 'Wallet ID', key: 'walletId' },
              { label: 'Transaction ID', key: 'txId' },
            ]}
            placeholder="select prefix"
            onChange={handleSelect}
            styles={base}
          />
        </div>
        <input
          className="w-full bg-transparent border-b p-1 focus:outline-none"
          placeholder={searchCategory.label}
          onChange={handleSearchInputChange}
          value={searchQuery}
        />
      </div>
      <div className={`${baseCard} ${role === 'admins' && 'border-2 !border-green-500'}`}>
        <div className=" space-x-2" align="right">
          <FilterEntries filter={filter} setFilter={setFilter} jump={jump} />
        </div>
        {page.data().length ? (
          <>
            {pageNavigation}
            <table className="w-full">
              <thead>
                <tr className="w-full h-8 px-4 text-xs font-semibold leading-none text-left text-white">
                  <th></th>
                  <th>
                    <button
                      onClick={() => sortBy('txId')}
                      className={`${getClassNamesFor('txId')} flex items-center`}
                    >
                      <span className="pl-4">Transaction ID</span>
                      <span className="pl-2">{showCaret('txId')}</span>
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => sortBy('walletId')}
                      className={`${getClassNamesFor('walletId')} flex items-center`}
                    >
                      <span className="pl-4">Wallet ID</span>
                      <span className="pl-2">{showCaret('walletId')}</span>
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => sortBy('amount')}
                      className={`${getClassNamesFor('amount')} flex items-center`}
                    >
                      <span className="pl-4">Amount</span>
                      <span className="pl-2">{showCaret('amount')}</span>
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => sortBy('dateCreated')}
                      className={`${getClassNamesFor('dateCreated')} flex items-center`}
                    >
                      <span>Date Created</span>
                      <span className="pl-2">{showCaret('dateCreated')}</span>
                    </button>
                  </th>
                  <th colSpan="2">Approval</th>
                </tr>
              </thead>
              <tbody className="w-full">
                {page.data().map(tx => (
                  <TransactionListItem key={tx.txId} txData={tx} searchQuery={searchQuery} />
                ))}
              </tbody>
            </table>
            <div className="flex items-center justify-center w-full py-4 text-sm text-white">
              {page.from} - {page.to} of {sortedItems.length} transactions
            </div>
            {pageNavigation}
          </>
        ) : isLoaded && apiError ? (
          <div className="flex flex-col items-center justify-center w-full py-4 pt-8 text-sm text-white">
            Error retrieving transactions.
            <button
              className="inline-flex items-start justify-start px-6 py-4 mt-5 text-white rounded bg-slate-700 hover:bg-slate-600 focus:outline-none"
              onClick={() => window.location.reload()}
            >
              Try Again
            </button>
          </div>
        ) : isLoaded ? (
          <div className="flex items-center justify-center w-full py-4 pt-8 text-sm text-white">
            No transactions
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default TransactionList;
