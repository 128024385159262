import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { toast } from 'react-hot-toast';

import { createNewWallet, getWalletAssets } from '../../../../store/wallets';
import { currentComponent, hideModal, showModal } from '../../../../store/modal';
import { getManagers } from '../../../../store/users';

import ManagerList from '../ManagerList';
import { icon } from '../../../../config/tailwind.classnames';
import { base } from '../../../../config/select.styles';
import { NetworkLogo } from '../../../../assets/networks';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { nativeTokenByNetwork } from '../../../../utils/networkTools';

function NewWallet() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wallets = useSelector(state => state.wallets);

  const assets = wallets.assets?.reduce((acc, asset) => {
    acc.push(...asset.data.map(assetData => ({
      network: asset.network,
      id: [asset.network, assetData.asset].join('-'),
      ...assetData,
    })));
    return acc;
  }, []);

  const [owner, setOwner] = useState('');
  const [activeHover, setActiveHover] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState({
    bitcoin: false,
    ethereum: false,
    avalanche: false,
    polymesh: false,
  });

  const { user, role } = useSelector(state => state.session);
  const _mgrData = useSelector(state => state?.users && state.users.managers);
  const [managers, setManagers] = useState({
    added: [user.email],
    selected: '',
    list: '',
  });

  useEffect(() => {
    dispatch(
      getWalletAssets({})
    )
      .catch(e => {
        console.log(e)
        toast.error(`Error getting wallet assets: ${e}`);
      });
  }, [])

  useEffect(() => {
    if (user.groups.includes('investors')) {
      dispatch(getManagers());
      return;
    }
    toast.dismiss();
    toast.error('Not authorized to create new wallet as admin.');
    navigate('/dashboard');
  }, [user.groups, dispatch, role, navigate]);

  useEffect(() => {
    if (_mgrData) {
      setManagers(prev => ({
        ...prev,
        list: _mgrData
          .filter(mgr => !managers.added.includes(mgr))
          .map(mgr => ({ label: mgr, value: mgr })),
      }));
    }
  }, [_mgrData, managers.added]);

  const handleSubmit = async e => {
    e.preventDefault();
    toast.dismiss();
    toast.loading('Working...');

    if (managers.added.length < 2) {
      toast.dismiss();
      toast.error('There must be a minimum of two managers.');
      return;
    }

    if (!owner) {
      toast.dismiss();
      toast.error('Please enter an owner name.');
      return;
    }

    if (!user) {
      return;
    }
    const assets = Object.keys(selectedAssets)
      .filter(key => selectedAssets[key] === true)
      .map(assetId => {
        const [network, asset] = assetId.split('-')
        return {
          network,
          asset,
        };
      });

    dispatch(
      createNewWallet({
        managers: managers.added,
        owner,
        orgId: user.organization,
        assets,
      })
    )
      .then(res => {
        toast.dismiss();
        if (res && res[0]?.error) {
          toast.error(`${res[0].error}`);
        } else {
          navigate(`/dashboard/wallets/${res.walletId}`);
          toast.success('Wallet created successfully!');
        }
      })
      .catch(e => {
        console.error(e);
        toast.dismiss();
        toast.error(`Error creating wallet: ${e}`);
      });
  };

  const showManagerList = () => {
    dispatch(
      currentComponent(() => <ManagerList managers={managers.added.slice(10)} fn={removeManager} />)
    );
    dispatch(showModal());
  };

  const removeManager = mgr => {
    setManagers(prev => ({ ...prev, added: prev.added.filter(m => m !== mgr) }));
    dispatch(hideModal());
  };

  const addManagerToList = () => {
    if (!managers.selected) return;
    if (!_mgrData) {
      toast.error('Manager list has not yet been loaded. Please wait...');
      return;
    }

    setManagers(prev => ({ ...prev, added: [...prev.added, managers.selected] }));
    setManagers(prev => ({ ...prev, selected: '' }));
  };

  const toggleAsset = asset =>
    setSelectedAssets(prev => ({ ...prev, [asset]: !selectedAssets[asset] }));
  const assetHover = asset => setActiveHover(asset);
  const goBack = () => navigate('/dashboard/wallets');

  const assetBtn =
    'relative bg-gray-800 hover:bg-gray-700 p-3 rounded-lg w-28 flex items-center justify-center m-0.5 min-w-[60px]';
  const assetBtnOn =
    'relative bg-green-700 hover:bg-green-600 p-3 rounded-lg w-28 flex items-center justify-center m-0.5 min-w-[60px]';
  const assetHoverBase =
    'absolute bg-black top-0 bottom-0 left-0 right-0 rounded-lg bg-opacity-80 font-bold text-lg text-white justify-center items-center flex opacity-1 transition-all duration-400';

  return (
    <div className="flex ml-5 mt-16">
      <form onSubmit={handleSubmit} className="w-full bg-gray-900 p-10 rounded max-w-4xl">
        <div className="flex justify-between mb-5">
          <h1 tabIndex={0} className="focus:outline-none text-3xl font-bold text-gray-100">
            Create New Wallet
          </h1>
        </div>

        <div className="text-sm bg-black rounded-lg w-full p-5">
          <h3 className="mb-2">Assigned Managers:</h3>
          <div className="w-full py-3 flex flex-wrap">
            {managers.added.slice(0, 10).map((mgr, i) => (
              <div className="text-xs flex rounded bg-gray-500 w-fit p-2 m-1" key={i}>
                <span className="mr-1">{mgr}</span>
                <span>
                  {i === 0 ? (
                    '(You)'
                  ) : (
                    <XMarkIcon
                      onClick={() => removeManager(mgr)}
                      className="w-4 hover:text-red-500 cursor-pointer"
                    />
                  )}
                </span>
              </div>
            ))}
            {managers.added.slice(10).length ? (
              <div className="text-xs flex w-fit p-2 m-1">
                <span className="mr-1 hover:text-blue-300 cursor-pointer" onClick={showManagerList}>
                  Show more...
                </span>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="flex items-center">
            <label className="w-[150px] min-w-[150px] text-sm">Add managers:</label>
            <Select
              name="asset"
              onChange={e => setManagers(prev => ({ ...prev, selected: e.value }))}
              className="w-[500px] text-sm"
              value={{ value: managers.selected, label: managers.selected }}
              options={managers.list || []}
              styles={base}
            />
            <button
              type="button"
              className={`${
                !managers.selected ? 'cursor-default opacity-30' : 'hover:bg-gray-500'
              } bg-gray-600 p-3 w-24 text-sm shadow rounded ml-5 `}
              onClick={addManagerToList}
            >
              Add
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-8 pt-10 items-center">
          <label className="text-sm text-gray-200 h-full flex items-center">Owner</label>
          <div className="col-span-2 flex h-full justify-evenly w-full items-center">
            <input
              onChange={e => setOwner(e.target.value)}
              autoComplete="off"
              type="text"
              data-lpignore="true"
              placeholder="owner name"
              value={owner}
              className="text-right flex text-sm bg-transparent text-white p-3 h-10 border-b mr-3 w-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-8 pt-10 items-center flex flex-wrap">
          <label className="text-sm text-gray-200 h-full flex items-center">Add Assets</label>
          <div className="col-span-2 flex justify-between mr-3">
            {
              assets?.map(assetData =>
              <button
                type="button"
                name={assetData.id}
                onClick={() => toggleAsset(assetData.id)}
                onMouseEnter={() => assetHover(assetData.id)}
                onMouseLeave={() => assetHover(null)}
                className={selectedAssets[assetData.id] ? assetBtnOn : assetBtn}
              >
                <NetworkLogo network={nativeTokenByNetwork(assetData.network)} token={assetData.asset}  size="s" />
                <div className={`${activeHover !== assetData.id && 'opacity-0'} ${assetHoverBase}`}>
                  { assetData.asset.toUpperCase() }
                </div>
              </button>
            )}
          </div>
        </div>

        <div className="w-full mt-8 flex justify-evenly">
          <button
            className={`${icon.green} mr-3 hover:bg-gradient-to-br hover:from-green-400 hover:to-green-500/75`}
            type="submit"
          >
            Submit
          </button>
          <button className={`${icon.grey} !text-white ml-3`} onClick={goBack} type="button">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewWallet;
