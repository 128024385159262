import OrganizationFilter from '../../../utils/OrganizationFilter';
import { useUser } from '../../../hooks/useUser';
// import { AdminAccessBanner } from '../../misc/AdminAccessBanner';

const WalletsHeader = ({ props }) => {
  const { organizationVisibility, setOrganizationVisibility } = props;
  // const { show, count } = disabled;
  const { user } = useUser();
  const isSuperAdmin = user?.groups.includes('superadmins');
  // const isAdmin = user?.groups.includes('admins');

  if (isSuperAdmin)
    return (
      <>
        {/* <div className="flex items-center justify-between text-sm text-red-500">
           <div className="flex items-center">
            <AdminAccessBanner title="SUPER ADMIN" />
          </div>
          {count ? (
            <button
              className="text-sm opacity-50 hover:opacity-100"
              onClick={() => setDisabled(prev => ({ ...prev, show: !prev.show }))}
            >
              {show ? 'Hide disabled wallets' : `Show disabled wallets (${count} hidden)`}
            </button>
          ) : (
            <div className="text-gray-600">No disabled wallets</div>
          )}
        </div> */}
        <OrganizationFilter
          organizationVisibility={organizationVisibility}
          setOrganizationVisibility={setOrganizationVisibility}
        />
      </>
    );

  // if (isAdmin)
  //   return (
  //     <div className="flex items-center justify-between text-sm text-green-500 bg-opacity-80">
  //       <div className="flex items-center">
  //         <AdminAccessBanner title="ADMIN" />
  //       </div>
  //       {disabled.count ? (
  //         <button
  //           className="text-sm opacity-50 hover:opacity-100"
  //           onClick={() => setDisabled(prev => ({ ...prev, show: !prev.show }))}
  //         >
  //           {show ? 'Hide disabled wallets' : `Show disabled wallets (${count} hidden)`}
  //         </button>
  //       ) : (
  //         <div className="text-gray-600">No disabled wallets</div>
  //       )}
  //     </div>
  //   );

  // return (
  //   <div>
  //     <p className="text-sm mt-2 text-gray-400">Manager ID: {user?.email}</p>
  //     <div className={`${count.length > 0 ? 'text-sm text-red-500' : 'hidden'}`}>
  //       Note: {count} hidden. Contact admin for details.
  //     </div>
  //   </div>
  // );
};

export default WalletsHeader;
